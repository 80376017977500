
@import "../index.less";

.fallback {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .loading {
    margin-left: 50px;
  }
}

.homeScreenContainer {
  height: 100vh;
  width: 100vw;
  background-color: #fdfdfd;

  .headerSection {
    display: flex;
    justify-content: space-between;
    height: @header-height;
    .logo {
      color: #fdfdfd;
      font-size: 32px;
      cursor: pointer;
      overflow: hidden;

      img {
        width: 125px;
        height: 50px;
      }
    }
  }

  .dropDown {
    display: none;
  }

  .logoutButton {
    background-color: #001629;
    color: #fdfdfd;
    margin-top: 18px;
  }

  .backgroundImage {
    height: 150px;
  }
}

@media (max-width: 760px) {
  .homeScreenContainer {
    .headerSection {
      padding: 0 10px;
      height: @header-height-mobile;
      line-height: normal;

      .dropDown {
        display: block;
        background-color: #001629;
        color: #fdfdfd;
        margin-top: 10px;
      }

      .logoutButton {
        display: none;
      }

      .logo {
        font-size: 24px;
        padding-top: 3px;

        img {
          width: 125px;
          height: 40px;
        }
      }
    }
  }
}
  